import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import PageLoader from "../Layout/PageLoader";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { motion } from 'framer-motion';

const initialFieldValues = {
    packageEnquiryId: "00000000-0000-0000-0000-000000000000",
    destinationId: "00000000-0000-0000-0000-000000000000",
    customerName: "",
    email: "",
    mobile: "",
    status: "NEW",
    travelDate: new Date(),
    returnDate: new Date(),
    travellers: 0,
    nights: 0,
    category: 4,
    notes: "",
    flight: "on",
    hotel: "on",
    transfers: "on",
    sightseeing: "on",
    visa: "on",
    tourType: "FAMILY"
};
export default function Tour() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [holidayDestinations, setHolidayDestinations] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [status, setStatus] = useState("");
    const [errors, setErrors] = useState({});
    const [tours, setTours] = useState([]);
    const [tempTours, setTempTours] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const selectToDate = (nextdate) => {
        var todate = new Date();
        if (nextdate === undefined) {
            todate = addDays(fromDate)
        }
        else {
            todate = nextdate;
        }
        if (todate < fromDate) {
            alert("To Date should be greater than are equal to From Date")
            addDays(fromDate);
        }
        else {
            setFromDate(fromDate);
            setToDate(todate);
        }
    };
    const addDays = (cindate) => {
        console.log(cindate)
        console.log(toDate)
        if (cindate === undefined) {
            cindate = new Date();
        }
        const newDate = new Date(cindate.getTime());
        if (newDate > toDate) {
            newDate.setDate(cindate.getDate() + 1);
            setToDate(newDate);
        }
        else {
            setToDate(toDate)
        }
        setFromDate(cindate);
        return newDate;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.customerName = values.customerName === "" ? false : true;
        temp.mobile = values.mobile === "" ? false : true;
        temp.nights = values.nights === 0 ? false : true;
        temp.destinationId = values.destinationId === "00000000-0000-0000-0000-000000000000" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("packageEnquiryId", "00000000-0000-0000-0000-000000000000")
            formData.append("destinationId", values.destinationId)
            formData.append("customerName", values.customerName)
            formData.append("email", values.email)
            formData.append("mobile", values.mobile)
            formData.append("travelDate", moment(values.travelDate).format('YYYY-MM-DD'))
            formData.append("returnDate", moment(values.returnDate).format('YYYY-MM-DD'))
            formData.append("nights", values.nights)
            formData.append("travellers", values.travellers)
            formData.append("category", values.category)
            formData.append("flight", values.flight)
            formData.append("hotel", values.hotel)
            formData.append("sightseeing", values.sightseeing)
            formData.append("visa", values.visa)
            formData.append("transfers", values.transfers)
            formData.append("notes", values.notes)
            formData.append("tourType", values.tourType)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) => axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATEPACKAGEENQUIRY, newrecord, { ...headerconfig })
        };
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const addOrEdit = (formData) => {
        if (formData.get('packageEnquiryId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        setStatus("Enquiry Submitted Successfully");
                        resetForm();
                        setBtnSubmit(true);
                    }
                    else {
                        setStatus("");
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    setStatus("");
                    setBtnSubmit(true);
                });
        }
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const handleTour = (e, value) => {
        if (value !== "0") {
            const filteredFlights = tempTours.filter(flight => {
                return flight.tFSegments.some(segment => segment.airline === value);
            });
            setTours(filteredFlights)
        } else {
            setTours(tempTours)
        }
    }
    const GetTours = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETTOURS, { ...headerconfig })
            .then((response) => {
                setTours(response.data.data.data);
                setTempTours(response.data.data.data);
                setLoading(false)
            });
    };
    const GetHolidayDestinations = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLHOLIDAYDESTINATIONS, { ...headerconfig })
            .then((response) => {
                setHolidayDestinations(response.data.data);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetTours();
        //GetHolidayDestinations();
    }, []);
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const images = [
        { url: '/assets/images/slider/slider2.png', text: 'Discover your perfect getaway - Book your dream holiday today!' },
        { url: '/assets/images/slider/slider3.jpg', text: 'Discover your perfect getaway - Book your dream holiday today!' },
        { url: '/assets/images/slider/slider1.png', text: 'Discover your perfect getaway - Book your dream holiday today!' }
      ];
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <section className="gray-simple">                        
                        <div className="container">
                        <Carousel responsive={responsive}>
                            {images.length > 0 && images.map((image, index) =>
                                <div key={index} style={{ position: 'relative', textAlign: 'center' }}>
                                <img src={image.url} alt={`Slide ${index + 1}`} style={{ width: '100%', height: '400px' }} />
                                <motion.div
                                  initial={{ opacity: 0, y: 0 }}
                                  animate={{ opacity: 1, y: 200 }}
                                  transition={{ duration: 10 }}
                                  style={{
                                    position: 'absolute',
                                    top: '10%',
                                    left: '20%',
                                    transform: 'translateX(-50%)',
                                    color: 'white',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    textShadow: '2px 2px 4px rgba(0,0,0,0.7)'
                                  }}
                                >
                                  {image.text}
                                </motion.div>
                              </div>
                            )}
                        </Carousel>
                            <div className="row justify-content-between mt-4">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-xl-4 col-lg-4 col-md-4">
                                            <h5 className="fw-bold fs-6 mb-lg-0 mb-3">Showing {tours.length} Search Results</h5>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-12">
                                            <div className="d-flex align-items-center justify-content-start justify-content-lg-end flex-wrap">
                                                {/* <div className="flex-first me-2">
                                                    <button className="btn btn-filter btn-dark" type="button" data-bs-toggle="modal" data-bs-target="#login"><i className="fa-solid fa-phone me-2" /><span className="d-none d-md-block">Enquiry Now</span></button>
                                                </div>
                                                <div className="flsx-first mt-sm-0 mt-2">
                                                    <ul className="nav nav-pills nav-fill p-1 small lights blukker bg-primary rounded-3 shadow-sm" id="filtersblocks" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link active rounded-3" id="trending" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" onClick={e => handleTour(e, "FAMILY")}>Family Tour</button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link rounded-3" id="mostpopular" data-bs-toggle="tab" type="button" role="tab" aria-selected="false" onClick={e => handleTour(e, "GROUP")}>Group Tour</button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link rounded-3" id="lowprice" data-bs-toggle="tab" type="button" role="tab" aria-selected="false" onClick={e => handleTour(e, "HONEYMOON")}>Honey Moon</button>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row justify-content-center gy-4 gx-xl-4 gx-3">
                                        {tours.length > 0 && tours.map(t =>
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                <div className="pop-touritem">
                                                    <Link href="#" className="card rounded-3 m-0">
                                                        <div className="flight-thumb-wrapper p-2 pb-0">
                                                            <div class=" position-absolute top-0 left-0 ms-3 mt-3 z-1">
                                                                <div class="label bg-primary text-light d-inline-flex align-items-center justify-content-center">INR {t.totalSalePrice}</div>
                                                            </div>
                                                            <div className="popFlights-item-overHidden rounded-3">
                                                                <img src={t.imageSrc} className="img-fluid" alt={t.imageName} style={{ minHeight: "300px", maxHeight: "300px", minWidth: "500px", maxWidth: "500px" }} />
                                                            </div>
                                                        </div>
                                                        <div className="touritem-middle position-relative p-3">
                                                            <div className="explot">
                                                                <h4 className="city fs-title m-0 fw-bold">
                                                                    <span>{t.name}, {t.destinationName}</span>
                                                                </h4>
                                                            </div>
                                                            <div className="touritem-flexxer mt-4">
                                                                <div className="tourist-wooks position-relative mb-3">
                                                                    <ul className="activities-flex">
                                                                        <li>
                                                                            <div className="actv-wrap">
                                                                                <div className="actv-wrap-ico"><i className="fa-solid fa-plane" /></div>
                                                                                <div className="actv-wrap-caps">Flights</div>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="actv-wrap">
                                                                                <div className="actv-wrap-ico"><i className="fa-solid fa-building-wheat" /></div>
                                                                                <div className="actv-wrap-caps">Hotels</div>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="actv-wrap">
                                                                                <div className="actv-wrap-ico"><i className="fa-solid fa-person-walking-luggage" /></div>
                                                                                <div className="actv-wrap-caps">Activity</div>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="actv-wrap">
                                                                                <div className="actv-wrap-ico"><i className="fa-solid fa-bus" /></div>
                                                                                <div className="actv-wrap-caps">Transfers</div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="trsms-foots mt-4">
                                                                            <div className="col-auto">
                                                                                <Link to={"/tourdetails/" + t.tourId} className="btn btn-md btn-danger full-width fw-medium px-lg-4">View Details</Link>
                                                                            </div>
                                                                    {/* <div className="flts-flex-end">
                                                                        <div className="row align-items-center justify-content-end gx-2">
                                                                            <div className="col-auto">
                                                                                <button onClick={e => handleSubmit(e, t)} className="btn btn-md btn-primary full-width fw-medium px-lg-4">Apply Now</button>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            }
            <div className="modal fade" id="login" tabIndex={-1} role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered login-pop-form" role="document">
                    <div className="modal-content" id="loginmodal">
                        <div className="modal-header">
                            <h4 className="modal-title fs-6">ENQUIRE NOW</h4>
                            <a href="#" className="text-muted fs-4" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-square-xmark" /></a>
                        </div>
                        <div className="modal-body">
                            {status !== "" ?
                                <div class="alert alert-info shadow-md"> <i class="fas fa-check-circle"></i> {status} </div>
                                : ""}
                            <div className="modal-login-form py-4 px-md-3 px-0">
                                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                    <div className="form-floating mb-2">
                                        <select className="form-select" value={values.destinationId} name="destinationId" onChange={handleInputChange}>
                                            <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                            {holidayDestinations.map(hd =>
                                                <option value={hd.value}>{hd.label}</option>
                                            )}
                                        </select>
                                        {errors.destinationId === false ? (<div className="validationerror">Please select destination </div>) : ('')}
                                        <label>Destination</label>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input type="text" value={values.customerName} name="customerName" onChange={handleInputChange} className={"form-control" + applyErrorClass('customerName')} placeholder="Please enter customer name" />
                                        <label>Name</label>
                                        {errors.customerName === false ? (<div className="validationerror">Please enter customer name </div>) : ('')}
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input type="text" value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please enter email" />
                                        <label>Email</label>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input type="text" value={values.mobile} name="mobile" onChange={handleInputChange} className={"form-control" + applyErrorClass('mobile')} placeholder="Please enter mobile" />
                                        <label>Phone</label>
                                        {errors.mobile === false ? (<div className="validationerror">Please enter mobile </div>) : ('')}
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="status" className="form-label">Travel Date</label>
                                                <div className="input-group">
                                                    <ReactDatePicker className="form-control" wrapperClassName="datePicker" name="travelDate" selected={values.travelDate} onChange={(date) => setValues({ ...values, ['travelDate']: date })} dateFormat="EEE dd MMM yyyy" minDate={new Date()} />
                                                </div>
                                                {errors.travelDate === false ? (<div className="validationerror">Please select travelDate </div>) : ('')}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="mb-3">
                                                <label htmlFor="nights" className="form-label">Nights*</label>
                                                <input min={1} type="number" className={"form-control" + applyErrorClass('nights')} value={values.nights} name="nights" onChange={handleInputChange}></input>
                                                {errors.nights === false ? (<div className="validationerror">Nights </div>) : ('')}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="mb-3">
                                                <label htmlFor="travellers" className="form-label">Travellers</label>
                                                <input min={1} type="number" className={"form-control" + applyErrorClass('travellers')} value={values.travellers} name="travellers" onChange={handleInputChange}></input>
                                                {errors.travellers === false ? (<div className="validationerror">Please select travellers </div>) : ('')}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <label htmlFor="notes" className="form-label">Comments</label>
                                                <input type="text" value={values.notes} name="notes" onChange={handleInputChange} className={"form-control" + applyErrorClass('notes')} placeholder="Beach side villa, Veg Food..." />
                                                {errors.notes === false ? (<div className="validationerror">Please enter tour requirements </div>) : ('')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <select className="form-select" value={values.tourType} name="tourType" onChange={handleInputChange}>
                                            <option value="FAMILY">FAMILY TOUR</option>
                                            <option value="GROUP">GROUP TOUR</option>
                                            <option value="HONEYMOON">HONEY MOON</option>
                                            <option value="CORPORATE">CORPORATE</option>
                                        </select>
                                        <label>Vacation Type</label>
                                    </div>
                                    <div className="form-group">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            {
                                                btnSubmit === true ?
                                                    <button type="submit" className="btn btn-primary w-100">{values.packageEnquiryId === "00000000-0000-0000-0000-000000000000" ? "Submit" : "Update"}</button>
                                                    :
                                                    <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                            }
                                            <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}