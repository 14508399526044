import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default function PromoCodes() {
    const navigate = useNavigate();
    const [promocodes, setPromoCodes] = useState([]);
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const GetPromoCodes = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETPROMOCODES, { ...headerconfig })
            .then((response) => {
                if (response.data.data !== null) {
                setPromoCodes(response.data.data.data);
                }
            });
    };
    useEffect(() => {
        GetPromoCodes();
    }, []);
    return (
        <section className="pt-5">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-xl-8 col-lg-9 col-md-11 col-sm-12">
                        <div className="secHeading-wrap text-center mb-1">
                            <h3>Exclusive Deals & Offers</h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 p-0">
                        <Carousel responsive={responsive}>
                            {promocodes.length > 0 && promocodes.map(p =>
                                <div className="carousel-cell">
                                    <div className="card rounded-3 border-0 m-0 fltsOffers-card">
                                        <div className="card-body">
                                            <div class="offers-flex position-relative">
                                                <div class="offer-tags position-absolute start-0 top-0 mt-2 ms-2">
                                                    <span class="label text-light bg-success fw-medium">CODE : {p.code}</span>
                                                </div>
                                                <div class="offers-pic">
                                                    <img src={p.imageSrc} class="img-fluid rounded" width="400px" height="200px" style={{minHeight:"220px"}}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>

    )
}