import { useEffect, useRef, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Currency from "../Authentication/Currency";
import airports from '../Data/AirportList.json';
import PromoCodes from "../Master/PromoCode";
import Locations from "../Master/Locations";
import SearchOneWay from "./SearchOneWay";
import SearchRoundtrip from "./SeacrhRoundTrip";
import SearchMultiCity from "./SearchMulti";
export default function SearchFlight() {
    const navigate = useNavigate();
    const [travelerDisplay, setTravelerDisplay] = useState(false);
    const [isOneWay, setIsOneWay] = useState(true);
    const [isDomestic, setIsDomestic] = useState('DOMESTIC');
    const [preffered, setPreferred] = useState("REGULAR");
    const [journeyType, setJourneyType] = useState("ONEWAY");
    const [locationFrom, setLocationFrom] = useState('HYD');
    const [locationTo, setLocationTo] = useState('BOM');
    const [displayFrom, setDisplayFrom] = useState('Hyderabad(HYD)');
    const [displayTo, setDisplayTo] = useState('Mumbai(BOM)');
    const [cityFrom, setCityFrom] = useState('Hyderabad');
    const [cityTo, setCityTo] = useState('Mumbai');
    const [departureDate, setDepartureDate] = useState(new Date());
    const [returnDate, setReturnDate] = useState(new Date());
    const [adults, setAdults] = useState(1);
    const [kids, setKids] = useState(0);
    const [infants, setInfants] = useState(0);
    const [cabinClass, setCabinClass] = useState("ECONOMY");
    const [errors, setErrors] = useState({});
    const [from, setFrom] = useState('Hyderabad, India');
    const [to, setTo] = useState('Mumbai, India');
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const DelhiMumbaiFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'DEL',
            "locationTo": 'BOM',
            "displayFrom": 'Delhi Indira Gandhi Intl, Delhi(DEL)',
            "displayTo": 'Chhatrapati Shivaji, Mumbai(BOM)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Delhi',
            "cityTo": 'Mumbai',
            "userId": userId,
            "from": 'Delhi, India',
            "to": 'Mumbai, India',
            "isOneWay": true,
            "isDomestic": isDomestic
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const DelhiBangaloreFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'DEL',
            "locationTo": 'BLR',
            "displayFrom": 'Delhi Indira Gandhi Intl, Delhi(DEL)',
            "displayTo": 'Bengaluru Intl Arpt, Bengaluru(BLR)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Delhi',
            "cityTo": 'Bengaluru',
            "userId": userId,
            "from": 'Delhi, India',
            "to": 'Bengaluru, India',
            "isOneWay": true,
            "isDomestic": isDomestic,
            "requestedBy": "CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const DelhiHyderabadFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'DEL',
            "locationTo": 'HYD',
            "displayFrom": 'Delhi Indira Gandhi Intl, Delhi(DEL)',
            "displayTo": 'Shamshabad Rajiv Gandhi Intl Arpt, Hyderabad(HYD)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Delhi',
            "cityTo": 'Hyderabad',
            "userId": userId,
            "from": 'Delhi, India',
            "to": 'Hyderabad, India',
            "isOneWay": true,
            "isDomestic": isDomestic,
            "requestedBy": "CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const AhmedabadMumbaiFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'AMD',
            "locationTo": 'BOM',
            "displayFrom": 'Sardar Vallabh Bhai Patel Intl Arpt, Ahmedabad(AMD)',
            "displayTo": 'Chhatrapati Shivaji, Mumbai(BOM)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Ahmedabad',
            "cityTo": 'Mumbai',
            "userId": userId,
            "from": 'Ahmedabad, India',
            "to": 'Mumbai, India',
            "isOneWay": true,
            "isDomestic": isDomestic,
            "requestedBy": "CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const MumbaiDubaiFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'BOM',
            "locationTo": 'DXB',
            "displayFrom": 'Chhatrapati Shivaji, Mumbai(BOM)',
            "displayTo": 'Dubai Intl Arpt, Dubai(DXB)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Mumbai',
            "cityTo": 'Dubai',
            "userId": userId,
            "from": 'Mumbai, India',
            "to": 'Dubai, UAE',
            "isOneWay": true,
            "isDomestic": 'INTERNATIONAL',
            "requestedBy": "CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const DelhiTorontoFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'DEL',
            "locationTo": 'YYZ',
            "displayFrom": 'Delhi Indira Gandhi Intl, Delhi(DEL)',
            "displayTo": 'Lester B Pearson Intl, Toronto(YYZ)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Delhi',
            "cityTo": 'Toronto',
            "userId": userId,
            "from": 'Delhi, India',
            "to": 'Toronto, USA',
            "isOneWay": true,
            "isDomestic": 'INTERNATIONAL',
            "requestedBy": "CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const ChennaiSingaporeFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'MAA',
            "locationTo": 'SIN',
            "displayFrom": 'Chennai Arpt, Chennai(MAA)',
            "displayTo": 'Changi Intl Arpt, Singapore(SIN)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Chennai',
            "cityTo": 'Singapore',
            "userId": userId,
            "from": 'Chennai, India',
            "to": 'Singapore, Singapore',
            "isOneWay": true,
            "isDomestic": 'INTERNATIONAL',
            "requestedBy": "CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const DelhiLondonFlight = (e) => {
        e.preventDefault();
        const searchQuery = {
            "journeyType": 'ONEWAY',
            "locationFrom": 'DEL',
            "locationTo": 'LHR',
            "displayFrom": 'Delhi Indira Gandhi Intl, Delhi(DEL)',
            "displayTo": 'Heathrow, London(LHR)',
            "adults": adults,
            "kids": kids,
            "infants": infants,
            "cabinClass": cabinClass,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "cityFrom": 'Delhi',
            "cityTo": 'London',
            "userId": userId,
            "from": 'Delhi, India',
            "to": 'London, London',
            "isOneWay": true,
            "isDomestic": 'INTERNATIONAL',
            "requestedBy": "CUSTOMER",
        }
        navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
    }
    const validate = () => {
        let temp = {};
        temp.displayFrom = displayFrom === "" ? false : true;
        temp.displayTo = displayTo === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleJourneyType = (e) => {
        if (e.target.value === "ROUNDTRIP") {
            setIsOneWay(false)
        }
        else {
            setIsOneWay(true)
        }
        setJourneyType(e.target.value)
    }
    const handleSearch = (e) => {
        e.preventDefault();
        if (validate()) {
            const searchQuery = {
                "journeyType": journeyType,
                "locationFrom": locationFrom,
                "locationTo": locationTo,
                "displayFrom": displayFrom,
                "displayTo": displayTo,
                "adults": adults,
                "kids": kids,
                "infants": infants,
                "cabinClass": cabinClass,
                "departureDate": moment(departureDate).format('YYYY-MM-DD'),
                "returnDate": moment(returnDate).format('YYYY-MM-DD'),
                "cityFrom": cityFrom,
                "cityTo": cityTo,
                "userId": userId,
                "from": from,
                "to": to,
                "isOneWay": isOneWay,
                "isDomestic": isDomestic,
                "requestedBy": "CUSTOMER",
            }
            if (isOneWay) {
                navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
            }
            else {
                if (isDomestic === "INTERNATIONAL") {
                    navigate('/oneway-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
                }
                else {
                    navigate('/roundtrip-flights/' + adults + "/" + kids + "/" + infants + "/" + isDomestic, { state: { searchQuery } });
                }
            }
        }
    }
    const handleDisplayClick = () => {
        setTravelerDisplay(travelerDisplay => !travelerDisplay);
    }
    const handleClassType = (e) => {
        setCabinClass(e.target.value)
    }
    const handlePrefferedType = (e) => {
        setPreferred(e.target.value)
    }
    const handleIncreaseAdults = (e) => {
        setAdults(parseFloat(adults + 1))
    }
    const handleDecreaseAdults = (e) => {
        if (adults !== 1) {
            setAdults(parseFloat(adults - 1))
        }
    }
    const handleIncreaseKids = (e) => {
        setKids(parseFloat(kids + 1))
    }
    const handleDecreaseKids = (e) => {
        if (kids !== 0) {
            setKids(parseFloat(kids - 1))
        }
    }
    const handleIncreaseInfants = (e) => {
        setInfants(parseFloat(infants + 1))
    }
    const handleDecreaseInfants = (e) => {
        if (infants !== 0) {
            setInfants(parseFloat(infants - 1))
        }
    }
    const handleDepartureDate = (date) => {
        if (date !== null) {
            setDepartureDate(date)
            addDays(date)
        }
    }
    const handleReturnDate = (date) => {
        if (date !== null) {
            setReturnDate(date)
            addDays(date)
        }
    }
    const addDays = (departureDate) => {
        if (departureDate === undefined) {
            departureDate = new Date();
        }
        const newDate = new Date(departureDate.getTime());
        setReturnDate(newDate.setDate(departureDate.getDate()));
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const [departureResults, setDepartureResults] = useState([]);
    const [returnResults, setReturnResults] = useState([]);
    const inputDRef = useRef(null);
    const inputRRef = useRef(null);
    const handleDepartureAirportSearch = (e) => {
        const value = e.target.value;
        setDisplayFrom(value);
        if (value.length > 0) {
            const filteredAirports = airports
                .filter(airport =>
                    airport.airportCode.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            setDepartureResults(filteredAirports);
            if (filteredAirports.length === 0) {
                const filteredAirports1 = airports
                    .filter(airport =>
                        airport.cityName.toLowerCase().includes(value.toLowerCase())
                    )
                    .slice(0, 10);
                setDepartureResults(filteredAirports1);
            }
        } else {
            setDepartureResults([]);
        }
    };
    const handleDepartureAirportClick = (e) => {
        setReturnResults([]);
        let filteredAirports = airports.filter(function (p) {
            return p.display === true
        }).slice(0, 10);
        setDepartureResults(filteredAirports);
        inputDRef.current.select();
    };
    const handleDepartureSelect = (data) => {
        if (data.countryCode !== "IN") {
            setIsDomestic('INTERNATIONAL')
        }
        var DF = data.cityName + "(" + data.airportCode + ")";
        setDisplayFrom(DF);
        setLocationFrom(data.airportCode)
        setCityFrom(data.cityName);
        setFrom(data.cityName);
        setDepartureResults([]);
    }
    const handleReturnAirportSearch = (e) => {
        const value = e.target.value;
        setDisplayTo(value);
        if (value.length > 0) {
            const filteredAirports = airports
                .filter(airport =>
                    airport.airportCode.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            setReturnResults(filteredAirports);
            if (filteredAirports.length === 0) {
                const filteredAirports1 = airports
                    .filter(airport =>
                        airport.cityName.toLowerCase().includes(value.toLowerCase())
                    )
                    .slice(0, 10);
                setReturnResults(filteredAirports1);
            }
        } else {
            setReturnResults([]);
        }
    };
    const handleReturnSelect = (data) => {
        var DF = data.cityName + "(" + data.airportCode + ")";
        if (data.countryCode !== "IN") {
            setIsDomestic('INTERNATIONAL')
        }
        setDisplayTo(DF);
        setLocationTo(data.airportCode)
        setCityTo(data.cityName);
        setTo(data.cityName);
        setReturnResults([]);
    }
    const handleReturnAirportClick = (e) => {
        setDepartureResults([]);
        let filteredAirports = airports.filter(function (p) {
            return p.display === true
        }).slice(0, 10);
        setReturnResults(filteredAirports);
        inputRRef.current.select();
    };
    useEffect(() => {
        if (localStorage.getItem("userId") !== null) {
            setUserId(localStorage.getItem("userId"))
        }
        addDays(departureDate);
    }, [])
    return (
        <div id="main-wrapper">
            <Header></Header><div className="clearfix"></div>
            <div className="image-cover hero-header" style={{ background: 'url(/assets/img/2151022224.jpg)' }}>
                <div style={{ marginRight: "15px", marginLeft: "15px" }}>
                    {/* Search Form */}
                    <div className="searchableForm bg-white rounded-3 py-3 px-4">
                        <div className="row justify-content-between align-items-center g-4">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className="flx-start mb-sm-0 mb-2">
                                    <div className="form-check form-check-inline">
                                        <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="trip" id="oneway" onChange={e => handleJourneyType(e)} value="ONEWAY" defaultChecked />
                                        <label className="form-check-label text-dark" htmlFor="oneway">One Way</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="trip" id="roundtrip" onChange={e => handleJourneyType(e)} value="ROUNDTRIP" />
                                        <label className="form-check-label text-dark" htmlFor="return">Return</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="trip" id="multicity" onChange={e => handleJourneyType(e)} value="MULTICITY" />
                                        <label className="form-check-label text-dark" htmlFor="return">MultiCity</label>
                                    </div>
                                </div>
                            </div>
                            {journeyType === "MULTICITY" ?
                                <SearchMultiCity></SearchMultiCity>
                                :
                                journeyType === "ROUNDTRIP" ?
                                    <SearchRoundtrip></SearchRoundtrip>
                                    :
                                    <SearchOneWay></SearchOneWay>
                            }
                        </div>
                    </div>
                    {/* </row> */}
                </div>
            </div>
            <Locations></Locations>
            <section className="container">
                <div className="row g-4">
                    <div className="col-lg-6">
                        <div className="bg-white shadow-md rounded p-4">
                            <h3 className="text-6 mb-4">Popular Domestic Routes</h3>
                            <hr className="mx-n4" />
                            <p className="d-flex align-items-center">Delhi to Mumbai Flights <button onClick={e => DelhiMumbaiFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                            <p className="d-flex align-items-center">Delhi to Bangalore Flights <button onClick={e => DelhiBangaloreFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                            <p className="d-flex align-items-center">Delhi to Hyderabad Flights <button onClick={e => DelhiHyderabadFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                            <p className="d-flex align-items-center">Ahmedabad to Mumbai Flights <button onClick={e => AhmedabadMumbaiFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="bg-white shadow-md rounded p-4">
                            <h3 className="text-6 mb-4">Popular International Routes</h3>
                            <hr className="mx-n4" />
                            <p className="d-flex align-items-center">Mumbai to Dubai Flights <button onClick={e => MumbaiDubaiFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                            <p className="d-flex align-items-center">Delhi to Toronto Flights <button onClick={e => DelhiTorontoFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                            <p className="d-flex align-items-center">Chennai to Singapore Flights <button onClick={e => ChennaiSingaporeFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                            <p className="d-flex align-items-center">Delhi to London Flights <button onClick={e => DelhiLondonFlight(e)} type="button" className="btn btn-sm btn-danger shadow-none ms-auto"><i className="fas fa-search d-block d-sm-none" /> <span className="d-none d-sm-block">Search Flights</span></button></p>
                            <hr />
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
            <Currency></Currency>
        </div>
    );
}