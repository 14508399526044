import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
export default function Locations() {
    const navigate = useNavigate();
    return (
        <section className="gray-simple">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-xl-8 col-lg-9 col-md-11 col-sm-12">
                        <div className="secHeading-wrap text-center mb-5">
                            <h2>Popular Location To Stay</h2>
                            <p>Cicero famously orated against his political opponent Lucius Sergius Catilina.</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center g-xl-4 g-lg-4 g-3">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="card destination-card border-0 rounded-3 overflow-hidden m-0">
                            <div className="destination-card-wraps position-relative">
                                <div className="destination-card-thumbs">
                                    <div className="destinations-pics"><Link to={"/searchhotels"}><img src={"/assets/images/hotelocations/1.jpg"} style={{ width: "300px", minHeight: "300px", maxHeight: "300px" }} className="img-fluid" alt /></Link>
                                    </div>
                                </div>
                                <div className="destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2">
                                    <div className="exploterr-text">
                                        <h3 className="text-light fw-bold mb-1">New York</h3>
                                        <p className="detail ellipsis-container text-light">
                                            <span className="ellipsis-item__normal">500+ hotels & rentals</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="card destination-card border-0 rounded-3 overflow-hidden m-0">
                            <div className="destination-card-wraps position-relative">
                                <div className="destination-card-thumbs">
                                <div className="destinations-pics"><Link to={"/searchhotels"}><img src={"/assets/images/hotelocations/2.jpg"} style={{width:"300px", minHeight:"300px", maxHeight:"300px"}} className="img-fluid" alt /></Link>
                                    </div>
                                </div>
                                <div className="destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2">
                                    <div className="exploterr-text">
                                        <h3 className="text-light fw-bold mb-1">Abu Dhabi</h3>
                                        <p className="detail ellipsis-container text-light">
                                            <span className="ellipsis-item__normal">1000+ hotels & rentals</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="card destination-card border-0 rounded-3 overflow-hidden m-0">
                            <div className="destination-card-wraps position-relative">
                                <div className="destination-card-thumbs">
                                    <div className="destinations-pics"><Link to={"/searchhotels"}><img src={"/assets/images/hotelocations/3.jpg"} style={{width:"300px", minHeight:"300px", maxHeight:"300px"}} className="img-fluid" alt /></Link>
                                    </div>
                                </div>
                                <div className="destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2">
                                    <div className="exploterr-text">
                                        <h3 className="text-light fw-bold mb-1">Paris</h3>
                                        <p className="detail ellipsis-container text-light">
                                            <span className="ellipsis-item__normal">2000+ hotels & rentals</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="card destination-card border-0 rounded-3 overflow-hidden m-0">
                            <div className="destination-card-wraps position-relative">
                                <div className="destination-card-thumbs">
                                    <div className="destinations-pics"><Link to={"/searchhotels"}><img src={"/assets/images/hotelocations/4.jpg"} style={{width:"300px", minHeight:"300px", maxHeight:"300px"}} className="img-fluid" alt /></Link>
                                    </div>
                                </div>
                                <div className="destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2">
                                    <div className="exploterr-text">
                                        <h3 className="text-light fw-bold mb-1">China</h3>
                                        <p className="detail ellipsis-container text-light">
                                            <span className="ellipsis-item__normal">5000+ hotels & rentals</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="card destination-card border-0 rounded-3 overflow-hidden m-0">
                            <div className="destination-card-wraps position-relative">
                                <div className="destination-card-thumbs">
                                    <div className="destinations-pics"><Link to={"/searchhotels"}><img src={"/assets/images/hotelocations/5.jpg"} style={{width:"300px", minHeight:"300px", maxHeight:"300px"}} className="img-fluid" alt /></Link>
                                    </div>
                                </div>
                                <div className="destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2">
                                    <div className="exploterr-text">
                                        <h3 className="text-light fw-bold mb-1">Bangkok</h3>
                                        <p className="detail ellipsis-container text-light">
                                            <span className="ellipsis-item__normal">4000+ hotels & rentals</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="card destination-card border-0 rounded-3 overflow-hidden m-0">
                            <div className="destination-card-wraps position-relative">
                                <div className="destination-card-thumbs">
                                    <div className="destinations-pics"><Link to={"/searchhotels"}><img src={"/assets/images/hotelocations/6.jpg"} style={{width:"300px", minHeight:"300px", maxHeight:"300px"}} className="img-fluid" alt /></Link>
                                    </div>
                                </div>
                                <div className="destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2">
                                    <div className="exploterr-text">
                                        <h3 className="text-light fw-bold mb-1">Europe</h3>
                                        <p className="detail ellipsis-container text-light">
                                            <span className="ellipsis-item__normal">3000+ hotels & rentals</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="card destination-card border-0 rounded-3 overflow-hidden m-0">
                            <div className="destination-card-wraps position-relative">
                                <div className="destination-card-thumbs">
                                    <div className="destinations-pics"><Link to={"/searchhotels"}><img src={"/assets/images/hotelocations/7.jpg"} style={{width:"300px", minHeight:"300px", maxHeight:"300px"}} className="img-fluid" alt /></Link>
                                    </div>
                                </div>
                                <div className="destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2">
                                    <div className="exploterr-text">
                                        <h3 className="text-light fw-bold mb-1">India</h3>
                                        <p className="detail ellipsis-container text-light">
                                            <span className="ellipsis-item__normal">5000+ hotels & rentals</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="card destination-card border-0 rounded-3 overflow-hidden m-0">
                            <div className="destination-card-wraps position-relative">
                                <div className="destination-card-thumbs">
                                    <div className="destinations-pics"><Link to={"/searchhotels"}><img src={"/assets/images/hotelocations/8.jpg"} style={{width:"300px", minHeight:"300px", maxHeight:"300px"}} className="img-fluid" alt /></Link>
                                    </div>
                                </div>
                                <div className="destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2">
                                    <div className="exploterr-text">
                                        <h3 className="text-light fw-bold mb-1">California</h3>
                                        <p className="detail ellipsis-container text-light">
                                            <span className="ellipsis-item__normal">300 hotels</span>
                                            <span className="separate ellipsis-item__normal" />
                                            <span className="ellipsis-item">100 Rental</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}