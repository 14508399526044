import { useEffect, useRef, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HomeBanner from "../Home/HomeBanner";
import Login from "../Authentication/Login";
import Currency from "../Authentication/Currency";
import destinations from '../Data/SightseeingDestinations.json';
import PromoCodes from "../Master/PromoCode";
export default function SearchBus() {
    const navigate = useNavigate();
    const [travelerDisplay, setTravelerDisplay] = useState(false);
    const [locationFrom, setLocationFrom] = useState('');
    const [locationFromCode, setLocationFromCode] = useState(0);
    const [locationTo, setLocationTo] = useState('');
    const [locationToCode, setLocationToCode] = useState(0);
    const [travelDate, setTravelDate] = useState(new Date());
    const [adults, setAdults] = useState(1);
    const [kids, setKids] = useState(0);
    const [errors, setErrors] = useState({});
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const validate = () => {
        let temp = {};
        temp.locationFrom = locationFrom === "" ? false : true;
        temp.locationTo = locationTo === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSearch = (e) => {
        e.preventDefault();
        if (validate()) {
            const searchQuery = {
                "locationFrom": locationFrom,
                "locationFromCode": locationFromCode,
                "locationTo": locationTo,
                "locationToCode": locationToCode,
                "adults": adults,
                "travelDate": moment(travelDate).format('YYYY-MM-DD'),
                "userId": userId,
            }
            navigate('/buses/' + locationFrom + "/" + locationTo + "/" + adults + "/" + moment(travelDate).format('YYYY-MM-DD'), { state: { searchQuery } });
        }
    }
    const handleDisplayClick = () => {
        setTravelerDisplay(travelerDisplay => !travelerDisplay);
    }
    const handleIncreaseAdults = (e) => {
        setAdults(parseFloat(adults + 1))
    }
    const handleDecreaseAdults = (e) => {
        if (adults !== 1) {
            setAdults(parseFloat(adults - 1))
        }
    }
    const handleIncreaseKids = (e) => {
        setKids(parseFloat(kids + 1))
    }
    const handleDecreaseKids = (e) => {
        if (kids !== 0) {
            setKids(parseFloat(kids - 1))
        }
    }
    const handleDepartureDate = (date) => {
        if (date !== null) {
            setTravelDate(date);
        }
    }
    const [departureResults, setDepartureResults] = useState([]);
    const [arrivalResults, setArrivalResults] = useState([]);
    const inputDRef = useRef(null);
    const inputARef = useRef(null);
    const handleDepartureSearch = (e) => {
        const value = e.target.value;
        setLocationFrom(value);
        if (value.length > 0) {
            const filteredAirports = destinations
                .filter(airport =>
                    airport.destinationName.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            setDepartureResults(filteredAirports);
        } else {
            setDepartureResults([]);
        }
    };
    const handleArrivalSearch = (e) => {
        const value = e.target.value;
        setLocationTo(value);
        if (value.length > 0) {
            const filteredAirports = destinations
                .filter(airport =>
                    airport.destinationName.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            setArrivalResults(filteredAirports);
        } else {
            setArrivalResults([]);
        }
    };
    const handleDepartureClick = (e) => {
        let filteredAirports = destinations.filter(function (p) {
        }).slice(0, 10);
        setDepartureResults(filteredAirports);
        inputDRef.current.select();
    };
    const handleArrivalClick = (e) => {
        let filteredAirports = destinations.filter(function (p) {
        }).slice(0, 10);
        setArrivalResults(filteredAirports);
        inputARef.current.select();
    };
    const handleDepartureSelect = (data) => {
        setLocationFromCode(data.parentId);
        setLocationFrom(data.destinationName)
        setDepartureResults([]);
    }
    const handleArrivalSelect = (data) => {
        setLocationToCode(data.parentId);
        setLocationTo(data.destinationName)
        setArrivalResults([]);
    }
    useEffect(() => {
        if (localStorage.getItem("userId") !== null) {
            setUserId(localStorage.getItem("userId"))
        }
    }, [])
    return (
        <div id="main-wrapper">
            <Header></Header><div className="clearfix"></div>
            <div className="image-cover hero-header" style={{ background: 'url(/assets/img/2151022224.jpg)' }}>
                <div style={{ marginRight: "15px", marginLeft: "15px", width: "100%" }}>
                    <div className="searchableForm bg-white rounded-3 py-3 px-4">
                        <div className="row justify-content-between align-items-center g-4">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 position-relative">
                                <div className="form-group hdd-arrow mb-0">
                                    <h6 className="text-dark">FROM DESTINATION</h6>
                                    <input ref={inputDRef} className="form-control searchTextbox" type="text" placeholder="Search Destination" value={locationFrom} onChange={handleDepartureSearch} onClick={handleDepartureClick} onFocus={handleDepartureClick} />
                                    {errors.locationFrom === false ? (<div className="validationerror">Please select departure </div>) : ('')}
                                    {departureResults.length > 0 && (
                                        <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
                                            {departureResults.map((airport) => (
                                                <li className="locationListVideoItem" key={airport.destinationId} onClick={() => handleDepartureSelect(airport)}>
                                                    <div className="item-name">{airport.destinationName}</div>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 position-relative">
                                <div className="form-group hdd-arrow mb-0">
                                    <h6 className="text-dark">TO DESTINATION</h6>
                                    <input ref={inputARef} className="form-control searchTextbox" type="text" placeholder="Search Destination" value={locationTo} onChange={handleArrivalSearch} onClick={handleArrivalClick} onFocus={handleArrivalClick} />
                                    {errors.locationTo === false ? (<div className="validationerror">Please select arrival </div>) : ('')}
                                    {arrivalResults.length > 0 && (
                                        <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
                                            {arrivalResults.map((airport) => (
                                                <li className="locationListVideoItem" key={airport.destinationId} onClick={() => handleArrivalSelect(airport)}>
                                                    <div className="item-name">{airport.destinationName}</div>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                <h6 className="text-dark">TRAVEL DATE</h6>
                                <div className="position-relative">
                                    <ReactDatePicker autoComplete="false" className="form-control searchTextbox" wrapperClassName="datePicker" name="departureDate" selected={travelDate} onChange={(date) => handleDepartureDate(date)} dateFormat="EEEE, dd MMM yyyy" minDate={new Date()} />
                                    <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                    {errors.travelDate === false ? (<div className="validationerror">Please select departureDate </div>) : ('')}
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                <h6 className="text-dark">TRAVELLERS</h6>
                                <div className="form-group mb-0">
                                    <div className="booking-form__input guests-input mixer-auto" style={{ top: "60px !important" }}>
                                        <button name="guests-btn" id="guests-input-btn" className={travelerDisplay === true ? "open" : ""} onClick={handleDisplayClick}><i className="fas fa-users me-2" />{adults + " Adults, " + kids + " Kids"}</button>
                                        <div className={travelerDisplay === true ? "guests-input__options open" : "guests-input__options"} id="guests-input-options">
                                            <div>
                                                <span className="guests-input__ctrl minus" id="adults-subs-btn" onClick={handleDecreaseAdults}><i className="fa-solid fa-minus" /></span>
                                                <span className="guests-input__value"><span defaultValue={1} readOnly value={adults} name="adults">{adults}</span>Adults</span>
                                                <span className="guests-input__ctrl plus" id="adults-add-btn" onClick={handleIncreaseAdults}><i className="fa-solid fa-plus" /></span>
                                            </div>
                                            <div>
                                                <span className="guests-input__ctrl minus" id="children-subs-btn" onClick={handleDecreaseKids}><i className="fa-solid fa-minus" /></span>
                                                <span className="guests-input__value"><span defaultValue={0} readOnly value={kids} name="kids">{kids}</span>Kids</span>
                                                <span className="guests-input__ctrl plus" id="children-add-btn" onClick={handleIncreaseKids}><i className="fa-solid fa-plus" /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative" style={{ paddingTop: "25px" }}>
                                <button type="button" onClick={e => handleSearch(e)} className="btn btn-primary full-width fw-medium"><i className="fa-solid fa-magnifying-glass me-2"></i>SEARCH</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PromoCodes></PromoCodes>
            <Footer></Footer>
            <Login></Login>
        </div>
    );
}